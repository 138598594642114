<template>
  <!-- <el-popover popper-class="student-attendance-cell-wrapper" placement="top-start" trigger="hover" content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
  </el-popover> -->

  <div slot="reference" class="attendance-cell-content-wrapper">
        <span style="font-weight: 500; margin-right: 10px;">{{data.type == 'HOLS_BING' ? 'SL' : 'CL'}}</span>
        <span>{{$ezdate.format($ezdate.from(data.startTime), 'MM/dd HH:mm')}} - {{$ezdate.format($ezdate.from(data.endTime), 'MM/dd HH:mm')}}</span>
    </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
// .student-attendance-cell-wrapper {

    .attendance-cell-content-wrapper {
        width: fit-content;
        padding: 2px 4px;
        box-sizing: border-box;
        background-color: #F56C6C;
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

// }
</style>