import Vue from "vue";
import VueRouter from "vue-router";

import {
  UserUtil
} from "@/utils/user";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "main",
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/exports",
    name: "exports",
    component: () => import("@/pages/calendar/exports.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("@/pages/index.vue"),
    redirect: "/home",
    children: [{
        path: "/home",
        name: "home",
        component: () => import("@/pages/home/home.vue"),
      },
      {
        path: "/students",
        name: "student",
        component: () => import("@/pages/student/index.vue"),
      },
      {
        path: "/students/data-board",
        name: "StudentDataBoard",
        component: () => import("@/pages/student/data-board/index.vue"),
      },
      {
        path: "/staffs",
        name: "staff",
        component: () => import("@/pages/staff/index.vue"),
      },
      {
        path: "/my/clazzs",
        name: "my-clazzs",
        component: () => import("@/pages/workbench/clazz/my-clazz.vue"),
        // children: [{
        //     path: "/clazzs/home",
        //     name: "clazz-home",
        //     component: () => import("@/pages/workbench/clazz/home.vue"),
        //   },
        //   {
        //     path: "/clazzs/:id/info",
        //     name: "clazzs",
        //     component: () => import("@/pages/workbench/clazz/clazz-info.vue"),
        //   },
        // ],
      },
      {
        path: "/notifications",
        name: "notification",
        component: () => import("@/pages/communication/index.vue"),
      },
      {
        path: "/communications",
        name: "communication",
        component: () => import("@/pages/communication/index.vue"),
      },
      {
        path: "/communications/:id",
        name: "communication-info",
        component: () => import("@/pages/communication/info.vue"),
      },
      {
        path: "/communications/:id/reports",
        name: "communication-report",
        component: () => import("@/pages/communication/report.vue"),
      },
      {
        path: "/notices",
        name: "notice",
        component: () => import("@/pages/notice/index.vue"),
      },
      {
        path: "/workbench",
        name: "workbench",
        component: () => import("@/pages/workbench/index.vue"),
      },
      {
        path: "/workbench/limit",
        name: "workbenchLimit",
        component: () => import("@/pages/workbench/limit.vue"),
      },
      {
        path: "/workbench/limit/:id",
        name: "workbenchLimitEditor",
        component: () => import("@/pages/workbench/limit-editor.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/pages/calendar/index.vue"),
      },
      {
        path: "/cloud-drive/:id",
        name: "cloud-drive",
        component: () => import("@/pages/yunpan/index.vue"),
      },
      {
        path: "/cloud/tags",
        name: "cloud-tags",
        component: () => import("@/pages/yunpan/my-tag.vue"),
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () => import("@/pages/recruit/index.vue"),
      },
      {
        path: "/assets",
        name: "assets",
        component: () => import("@/pages/assets/index.vue"),
      },
      {
        path: "/hr/recruitment",
        name: "hr-recruitment",
        component: () => import("@/pages/workbench/hr/recruitment/index.vue"),
      },
      {
        path: "/hr/recruitment/configs",
        name: "hr-recruitment-configs",
        component: () => import("@/pages/workbench/hr/recruitment/configs.vue"),
      },
    ],
  },
  {
    path: "/lectures",
    name: "workbenchShow",
    component: () => import("@/pages/workbench/tingke/index.vue"),
    redirect: "/lectures/tasks",
    children: [{
        path: "/lectures/tasks",
        name: "workbenchAttendAlecture",
        component: () =>
          import("@/pages/workbench/tingke/attend-a-lecture.vue"),
      },
      {
        path: "/lectures/tasks/:id",
        name: "workbenchTaskDetails",
        component: () => import("@/pages/workbench/tingke/task-details.vue"),
      },
      {
        path: "/lectures/datas",
        name: "workbenchTotal",
        component: () => import("@/pages/workbench/tingke/total.vue"),
      },
      {
        path: "/lectures/single-tasking",
        name: "workbenchSingleTasking",
        component: () => import("@/pages/workbench/tingke/single-tasking.vue"),
      },
    ],
  },

  {
    path: "/lectures/report",
    name: "workbenchReport",
    component: () => import("@/pages/workbench/tingke/report.vue"),
  },

  {
    path: "/workflows",
    name: "workflowIndex",
    component: () => import("@/pages/workflow/index.vue"),
    redirect: "/workflows",
    children: [{
        path: "/workflows",
        name: "workflowHome",
        component: () => import("@/pages/workflow/home.vue"),
      },
      {
        path: "/workflows/:id/edit",
        name: "workflowEdit",
        component: () => import("@/pages/workflow/edit.vue"),
      },
      {
        path: "/workflows/:id",
        name: "workflowInfo",
        component: () => import("@/pages/workflow/info.vue"),
      },
    ],
  },

  {
    path: "/employeefile",
    name: "employeefileIndex",
    component: () => import("@/pages/workbench/employee-file/index.vue"),
    redirect: "/employeefile/home",
    children: [{
        path: "/employeefile/home",
        name: "employeefileHome",
        component: () => import("@/pages/workbench/employee-file/home.vue"),
      },
      {
        path: "/employeefile/roster",
        name: "employeefileRoster",
        component: () => import("@/pages/workbench/employee-file/roster.vue"),
      },
      {
        path: "/employeefile/roster/details",
        name: "employeefileRosterDetails",
        component: () =>
          import("@/pages/workbench/employee-file/roster-detail.vue"),
      },
      {
        path: "/employeefile/remind",
        name: "employeefileRemind",
        component: () => import("@/pages/workbench/employee-file/remind.vue"),
      },
      {
        path: "/employeefile/departuser",
        name: "employeefileDepartuser",
        component: () =>
          import("@/pages/workbench/employee-file/depart-user.vue"),
      },
      {
        path: "/employeefile/structure",
        name: "employeeStructure",
        component: () =>
          import("@/pages/workbench/employee-file/structure.vue"),
      },
      {
        path: "/employeefile/userinfo",
        name: "employeeUserinfo",
        component: () =>
          import("@/pages/workbench/employee-file/userinfo.vue"),
      },
      {
        path: "/employeefile/record",
        name: "employeeRecord",
        component: () => import("@/pages/workbench/employee-file/record.vue"),
      },
      {
        path: "/employeefile/uploadcontract",
        name: "employeeUploadcontract",
        component: () =>
          import("@/pages/workbench/employee-file/upload-contract.vue"),
      },
      {
        path: "/employeefile/module",
        name: "employeefileModule",
        component: () =>
          import("@/pages/workbench/employee-file/module/index.vue"),
      },
      {
        path: "/employeefile/module/schoolyear",
        name: "employeefileSchoolyear",
        component: () =>
          import("@/pages/workbench/employee-file/module/school-year.vue"),
      },
      {
        path: "/employeefile/module/worktime",
        name: "employeefileWorktime",
        component: () =>
          import("@/pages/workbench/employee-file/module/work-time.vue"),
      },
      {
        path: "/employeefile/module/calendar",
        name: "employeefileCalendar",
        component: () =>
          import("@/pages/workbench/employee-file/module/calendar.vue"),
      },
    ],
  },

  {
    path: "/attendance",
    name: "attendanceIndex",
    component: () => import("@/pages/workbench/attendance/index.vue"),
    redirect: "/attendance/home",
    children: [{
        path: "/attendance/home",
        name: "attendanceHome",
        component: () => import("@/pages/workbench/attendance/home.vue"),
      },
      {
        path: "/attendance/calendar",
        name: "attendanceCalendar",
        component: () => import("@/pages/workbench/attendance/calendar.vue"),
      },
      {
        path: "/attendance/modify-record",
        name: "attendanceModifyrecord",
        component: () =>
          import("@/pages/workbench/attendance/modify-record.vue"),
      },
      {
        path: "/attendance/overview",
        name: "attendanceOverview",
        component: () => import("@/pages/workbench/attendance/overview.vue"),
      },
      {
        path: "/attendance/remind",
        name: "attendanceRemind",
        component: () => import("@/pages/workbench/attendance/remind.vue"),
      },
      {
        path: "/attendance/details",
        name: "attendanceDetails",
        component: () => import("@/pages/workbench/attendance/details.vue"),
      },
      {
        path: "/attendance/approval",
        name: "attendanceApproval",
        component: () => import("@/pages/workbench/attendance/approval.vue"),
      },
      {
        path: "/attendance/approval/list",
        name: "attendanceApprovallist",
        component: () =>
          import("@/pages/workbench/attendance/approval-list.vue"),
      },
      {
        path: "/attendance/module",
        name: "attendanceModule",
        component: () =>
          import("@/pages/workbench/attendance/module/index.vue"),
      },
      {
        path: "/attendance/module/worktime",
        name: "attendanceWorktime",
        component: () =>
          import("@/pages/workbench/attendance/module/work-time.vue"),
      },
      {
        path: "/attendance/module/schoolyear",
        name: "attendanceSchoolyear",
        component: () =>
          import("@/pages/workbench/employee-file/module/school-year.vue"),
      },
      {
        path: "/attendance/records",
        name: "AttendanceRecord",
        component: () =>
          import("@/pages/workbench/attendance/record"),
      },
    ],
  },

  {
    path: "/payroll",
    name: "payrollIndex",
    component: () => import("@/pages/workbench/payroll/index.vue"),
    redirect: "/payroll/home",
    children: [{
        path: "/payroll/home",
        name: "payrollHome",
        component: () => import("@/pages/workbench/payroll/home.vue"),
      },
      {
        path: "/payroll/complete-attendance",
        name: "payrollCompleteAttendance",
        component: () =>
          import("@/pages/workbench/payroll/complete-attendance.vue"),
      },
      {
        path: "/payroll/roster",
        name: "payrollRoster",
        component: () => import("@/pages/workbench/payroll/roster.vue"),
      },
      {
        path: "/payroll/roster/details",
        name: "payrollRosterDetails",
        component: () => import("@/pages/workbench/payroll/roster-detail.vue"),
      },
      {
        path: "/payroll/overtime-record/",
        name: "payrollOvertimRecord",
        component: () =>
          import("@/pages/workbench/payroll/overtime-record.vue"),
      },
      {
        path: "/payroll/record/",
        name: "payrollRecord",
        component: () => import("@/pages/workbench/payroll/record.vue"),
      },
      {
        path: "/payroll/module",
        name: "payrollModule",
        component: () => import("@/pages/workbench/payroll/module/index.vue"),
      },
      {
        path: "/payroll/payroll-list",
        name: "payrollPayrollList",
        component: () => import("@/pages/workbench/payroll/payroll-list.vue"),
      },
      {
        path: "/payroll/module/school-year",
        name: "payrollSchoolYear",
        component: () =>
          import("@/pages/workbench/employee-file/module/school-year.vue"),
      },
      {
        path: "/payroll/module/wages-count",
        name: "payrollWagesCount",
        component: () =>
          import("@/pages/workbench/payroll/module/wages-count.vue"),
      },
      {
        path: "/payroll/year-end-reporting",
        name: "payrollYearendreporting",
        component: () =>
          import("@/pages/workbench/payroll/year-end-reporting.vue"),
      },
      {
        path: "/payroll/attendance-money-set",
        name: "payrollAttendancemoneyset",
        component: () =>
          import("@/pages/workbench/payroll/attendance-money-set.vue"),
      },
      {
        path: "/payroll/adjusting-record",
        name: "payrollAdjustingrecord",
        component: () =>
          import("@/pages/workbench/payroll/adjusting-record.vue"),
      },
    ],
  },

  {
    path: "/budget",
    name: "budgetIndex",
    component: () => import("@/pages/workbench/budget/index.vue"),
    redirect: "/budget/expend",
    children: [{
        path: "/budget/budget",
        name: "budgetBudget",
        component: () => import("@/pages/workbench/budget/budget.vue"),
      },
      {
        path: "/budget/expend",
        name: "budgetExpend",
        component: () => import("@/pages/workbench/budget/expend.vue"),
      },
      {
        path: "/budget/roster",
        name: "budgetRoster",
        component: () => import("@/pages/workbench/budget/roster.vue"),
      },

      {
        path: "/budget/folder",
        name: "budgetFolder",
        component: () => import("@/pages/workbench/budget/folder.vue"),
      },
      {
        path: "/budget/add_category",
        name: "budgetCategory",
        component: () => import("@/pages/workbench/budget/addCategory.vue"),
      },
      {
        path: "/budget/remind",
        name: "budgetRemind",
        component: () => import("@/pages/workbench/budget/remind.vue"),
      },
      {
        path: "/budget/monthRoster",
        name: "budgetMonthroster",
        component: () => import("@/pages/workbench/budget/monthRoster.vue"),
      },
      {
        path: "/budget/uploadRoster",
        name: "budgetUploadroster",
        component: () => import("@/pages/workbench/budget/uploadRoster.vue"),
      },
      {
        path: "/budget/expenditure",
        name: "budgetExpenditure",
        component: () => import("@/pages/workbench/budget/expenditure.vue"),
      },
      {
        path: "/budget/secondary_detail",
        name: "budgetSecondaryDetail",
        component: () =>
          import("@/pages/workbench/budget/secondaryDetail.vue"),
      },
      {
        path: "/budget/edit_secondary",
        name: "budgetEditSecondary",
        component: () => import("@/pages/workbench/budget/editSecondary.vue"),
      },
      {
        path: "/budget/payment_agency",
        name: "budgetPaymentAgency",
        component: () => import("@/pages/workbench/budget/paymentAgency.vue"),
      },
      {
        path: "/budget/payment_public",
        name: "budgetPaymentPublic",
        component: () => import("@/pages/workbench/budget/paymentPublic.vue"),
      },
      {
        path: "/budget/payment_procure",
        name: "budgetPaymentProcure",
        component: () => import("@/pages/workbench/budget/paymentProcure.vue"),
      },
      {
        path: "/budget/invoice_pool",
        name: "budgetInvoicePool",
        component: () => import("@/pages/workbench/budget/invoice-pool.vue"),
      },
      {
        path: "/budget/apply_pay",
        name: "budgetApplyPay",
        component: () => import("@/pages/workbench/budget/apply-pay.vue"),
      },
      {
        path: "/budget/add_apply_pay",
        name: "budgetAddapplyPay",
        component: () => import("@/pages/workbench/budget/add-apply-pay.vue"),
      },

      {
        path: "/budget/apply_pay_select",
        name: "budgetApplyPayselect",
        component: () =>
          import("@/pages/workbench/budget/apply-pay-select.vue"),
      },
      {
        path: "/budget/pay_ratify",
        name: "budgetPayRatify",
        component: () => import("@/pages/workbench/budget/pay-ratify.vue"),
      },
      {
        path: "/budget/pay_ratify_detail",
        name: "budgetPayRatifyDetail",
        component: () =>
          import("@/pages/workbench/budget/pay-ratify-detail.vue"),
      },

      {
        path: "/budget/secondary_detail/threeLevel",
        name: "budgetThreelevel",
        component: () => import("@/pages/workbench/budget/threeLevel.vue"),
      },
      {
        path: "/budget/secondary_detail/threeLevel/threeLevelDetail",
        name: "budgetThreeleveldetail",
        component: () =>
          import("@/pages/workbench/budget/threeLevelDetail.vue"),
      },
      {
        path: "/budget/secondary_detail/threeLevel/threeLevelDetail/purchasingList",
        name: "budgetPurchasinglist",
        component: () => import("@/pages/workbench/budget/purchasingList.vue"),
      },
      {
        path: "/budget/secondary_detail/threeLevel/threeLevelDetail/purchasingList/purchasingDetail",
        name: "budgetPurchasingDetail",
        component: () =>
          import("@/pages/workbench/budget/purchasingDetail.vue"),
      },
      {
        path: "/budget/secondary_detail/threeLevel/threeLevelDetail/publicInfo",
        name: "budgetPublicInfo",
        component: () => import("@/pages/workbench/budget/payinfoPublic.vue"),
      },
      {
        path: "/budget/apply_procure",
        name: "budgetApplyProcure",
        component: () => import("@/pages/workbench/budget/apply-procure.vue"),
      },
      {
        path: "/budget/procure_handling",
        name: "budgetProcureHandling",
        component: () =>
          import("@/pages/workbench/budget/procure-handling.vue"),
      },
      {
        path: "/budget/procure_approve",
        name: "budgetProcureApprove",
        component: () =>
          import("@/pages/workbench/budget/procure-approve.vue"),
      },
      {
        path: "/budget/apply_procure_select",
        name: "budgetApplyProcureselect",
        component: () =>
          import("@/pages/workbench/budget/apply-procure-select.vue"),
      },
      {
        path: "/budget/add_procure",
        name: "budgetAddProcure",
        component: () => import("@/pages/workbench/budget/add-procure.vue"),
      },
      {
        path: "/budget/procure_apply_detail",
        name: "budgetProcureApplyDetail",
        component: () =>
          import("@/pages/workbench/budget/procure-apply-detail.vue"),
      },
      {
        path: "/budget/procure_apply_detail/contrast",
        name: "budgetContrast",
        component: () => import("@/pages/workbench/budget/contrast.vue"),
      },
      {
        path: "/budget/share",
        name: "budgetShare",
        component: () => import("@/pages/workbench/budget/share.vue"),
      },
      {
        path: "/budget/handling_roster",
        name: "budgetHandlingPayRoster",
        component: () =>
          import("@/pages/workbench/budget/handling-pay-roster.vue"),
      },
      {
        path: "/budget/handling_list",
        name: "budgetHandlingList",
        component: () => import("@/pages/workbench/budget/handling-list.vue"),
      },
      {
        path: "/budget/handling_list/handling_confirm",
        name: "budgetHandlingConfirm",
        component: () =>
          import("@/pages/workbench/budget/handling-confirm.vue"),
      },
    ],
  },
  {
    path: "/stores",
    name: "storeIndex",
    component: () => import("@/pages/workbench/store/index.vue"),
    redirect: '/stores/shelves',
    children: [{
        path: "/stores/shelves",
        name: "shelves",
        component: () => import("@/pages/workbench/store/shelves.vue"),
      }, {
        path: "/stores/shelves/:id",
        name: "shelves-info",
        component: () => import("@/pages/workbench/store/shelves-info.vue"),
      },
      {
        path: "/stores/goods",
        name: "goods",
        component: () => import("@/pages/workbench/store/goods.vue"),
      },
      {
        path: "/stores/orders",
        name: "orders",
        component: () => import("@/pages/workbench/store/order-list.vue"),
      }
    ],
  },
  {
    path: "/scbus",
    name: "scbusIndex",
    component: () => import("@/pages/workbench/school-bus/index.vue"),
    redirect: '/scbus/lines',
    children: [{
        path: "/scbus/lines",
        name: "lines",
        component: () => import("@/pages/workbench/school-bus/line.vue"),
      }, {
        path: "/scbus/lines/:id",
        name: "lines-info",
        component: () => import("@/pages/workbench/school-bus/line-info.vue"),
      },
      {
        path: "/scbus/bus",
        name: "bus",
        component: () => import("@/pages/workbench/school-bus/bus.vue"),
      },
      {
        path: "/scbus/driving-records",
        name: "driving-records",
        component: () => import("@/pages/workbench/school-bus/driving-record.vue"),
      }
    ],
  },
  {
    path: "/health-care/feeding",
    name: "feeding",
    component: () => import("@/pages/workbench/health-care/feeding/index.vue"),
  },
  {
    path: "/health-care/canteen",
    name: "canteenIndex",
    component: () => import("@/pages/workbench/health-care/canteen/index.vue"),
    redirect: '/health-care/canteen/books',
    children: [{
      path: "/health-care/canteen/books",
      name: "canteen-books",
      component: () => import("@/pages/workbench/health-care/canteen/book.vue"),
    }, {
      path: "/health-care/canteen/dishes",
      name: "canteen-dishes",
      component: () => import("@/pages/workbench/health-care/canteen/dishes.vue"),
    }],
  },

  {
    path: "/clazzs/index",
    name: "clazz-index",
    component: () => import("@/pages/workbench/clazz/index.vue"),
    redirect: '/clazzs/home',
    children: [{
        path: "/clazzs/home",
        name: "clazz-home",
        component: () => import("@/pages/workbench/clazz/home.vue"),
      },
      {
        path: "/clazzs/:id/info",
        name: "clazzs",
        component: () => import("@/pages/workbench/clazz/clazz-info.vue"),
      },
    ],
  },
  {
    path: "/student-fee",
    name: "fee-index",
    component: () => import("@/pages/workbench/student-fee/index.vue"),
    redirect: '/student-fees',
    children: [{
      path: "/student-fees",
      name: "fee-home",
      component: () => import("@/pages/workbench/student-fee/home.vue"),
    }, {
      path: "/student-fee/categorys",
      name: "fee-category",
      component: () => import("@/pages/workbench/student-fee/fee-category.vue"),
    }, {
      path: "/student-fee/items",
      name: "fee-item",
      component: () => import("@/pages/workbench/student-fee/fee-item.vue"),
    }, {
      path: "/student-fee/refunds",
      name: "refund-index",
      component: () => import("@/pages/workbench/student-fee/refund/index.vue"),
    }, {
      path: "/student-fee/payment-reminders",
      name: "payment-reminder-index",
      component: () => import("@/pages/workbench/student-fee/payment-reminder/index.vue"),
    }, {
      path: "/student-fee/statements",
      name: "statement-index",
      component: () => import("@/pages/workbench/student-fee/statement/index.vue"),
    }, {
      path: "/student-fee/school-banks",
      name: "school-bank",
      component: () => import("@/pages/workbench/student-fee/school-bank.vue"),
    }],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  let toPath = to.path;
  let hasLogged = false;
  try {
    let user = UserUtil.get();
    if (user.token && user.token != "") {
      hasLogged = true;
    }
  } catch (e) {
    console.log(e);
    hasLogged = false;
  }
  console.log(`has logged : ${hasLogged}, to : ${toPath}`);
  if (!hasLogged && toPath != "/sign-in") {
    return next("/sign-in");
  } else if (hasLogged && toPath == "/sign-in") {
    return next("/home");
  }
  return next();
});

export default router;